const initstate = {
  UserData: {},
  isSidebarOpen: false,
  SidebarToggle: false,
  ShowIconsOnly: false,
  registerdata: [],
  forpatienteedit: [],
  isModalOpen: false,
  isModalShowVisible: false,
  forPatientData: [],
  SelectedPatientCalender: "",
  selectedRequestList: [],
  labformdata: [],
  Data: [],
  Selected_Patient_Pharmacy: [],
  foremployeedata: [],
  foruserregisteremployeedata: [],
  foremployeeedit: [],
  employeeIdget: [],
  selectedRowData: null,
  employeedata: [],
  formData: [],
  workbenchformData: [],
  foredituserregisteremployeedata: [],
  selectedSupplierIndex: null,
  selectedProductIndex: {},
  PurchaseInvoiceGRN: {},
  GRNedit: {},
  IndentRecieveListData: {},
  IndentIssueListData: {},
  IndentReturnListData: {},
  Supplierpay: {},
  vistingdoctordata: [],
  TestData: {},
  Agesetup: {},
  TestMaster: {},
  Samplecapture: {},
  GroupMasterData: {},
  barcodedata: "",
  navigationlab: "",
  forpatientdatalab: {},
  toggled: false,
  foreditcontent: null,
  senioreditdoc: "",
  iseditedsenior: "",
  pathalogydoc: "",
  PrintBarcode: {},
  // UrlLink: "http://127.0.0.1:8000/",
  UrlLink:"https://drbailey.vesoft.co.in/",
  selecteddepartment: "",
  OfferPackageData: [],
  SelectedPateintDeuBilling: [],
  ExternalLabdata: null,
  RequestDataforBilling: null,
};

const userRecorddata = (state = initstate, action) => {
  switch (action.type) {
    case "UserData":
      // console.log(action);
      return { ...state, UserData: action.value };

    case "isSidebarOpen":
      // console.log('isSidebarOpen', action);
      return { ...state, isSidebarOpen: action.value };

      case 'SidebarToggle':  // Fixed typo here
      return { ...state, SidebarToggle: action.value };

  case 'ShowIconsOnly':
      return { ...state, ShowIconsOnly: action.value };


    case "isModalShowVisible":
      // console.log('isModalShowVisible', action);
      return { ...state, isModalShowVisible: action.value };

    case "isModalOpen":
      // console.log('isModalOpen', action);
      return { ...state, isModalOpen: action.value };

    case "toggled":
      // console.log('toggled', action);
      return { ...state, toggled: action.value };

    case "registerdata":
      // console.log('registerdata', action);
      return { ...state, registerdata: action.value };

    case "forpatienteedit":
      // console.log('forpatienteedit', action);
      return { ...state, forpatienteedit: action.value };

    case "forPatientData":
      // console.log('forPatientData', action);
      return { ...state, forPatientData: action.value };

    case "SelectedPatientCalender":
      // console.log('SelectedPatientCalender', action);
      return { ...state, SelectedPatientCalender: action.value };

    case "selectedRequestList":
      // console.log('selectedRequestList', action);
      return { ...state, selectedRequestList: action.value };

    case "labformdata":
      // console.log('labformdata', action);
      return { ...state, labformdata: action.value };

    case "Data":
      // console.log('Data', action);
      return { ...state, Data: action.value };

    case "Selected_Patient_Pharmacy":
      // console.log('Selected_Patient_Pharmacy', action);
      return { ...state, Selected_Patient_Pharmacy: action.value };

    case "foremployeedata":
      // console.log('foremployeedata', action);
      return { ...state, foremployeedata: action.value };

    case "foruserregisteremployeedata":
      // console.log('foruserregisteremployeedata', action);
      return { ...state, foruserregisteremployeedata: action.value };

    case "foremployeeedit":
      // console.log('foremployeeedit', action);
      return { ...state, foremployeeedit: action.value };

    case "employeeIdget":
      // console.log('employeeIdget', action);
      return { ...state, employeeIdget: action.value };

    case "selectedRowData":
      // console.log('selectedRowData', action);
      return { ...state, selectedRowData: action.value };

    case "employeedata":
      // console.log('employeedata', action);
      return { ...state, employeedata: action.value };

    case "formData":
      // console.log('formData', action);
      return { ...state, formData: action.value };

    case "workbenchformData":
      // console.log('workbenchformData', action);
      return { ...state, workbenchformData: action.value };

    case "foredituserregisteremployeedata":
      // console.log('foredituserregisteremployeedata', action);
      return { ...state, foredituserregisteremployeedata: action.value };

    case "selectedSupplierIndex":
      // console.log('selectedSupplierIndex', action);
      return { ...state, selectedSupplierIndex: action.value };

    case "selectedProductIndex":
      // console.log('selectedProductIndex', action);
      return { ...state, selectedProductIndex: action.value };

    case "PurchaseInvoiceGRN":
      // console.log('PurchaseInvoiceGRN', action);
      return { ...state, PurchaseInvoiceGRN: action.value };

    case "GRNedit":
      // console.log('GRNedit', action);
      return { ...state, GRNedit: action.value };

    case "IndentRecieveListData":
      // console.log('IndentRecieveListData', action);
      return { ...state, IndentRecieveListData: action.value };

    case "IndentIssueListData":
      // console.log('IndentIssueListData', action);
      return { ...state, IndentIssueListData: action.value };

    case "IndentReturnListData":
      // console.log('IndentReturnListData', action);
      return { ...state, IndentReturnListData: action.value };

    case "Supplierpay":
      //  console.log('Supplierpay', action);
      return { ...state, Supplierpay: action.value };

    case "vistingdoctordata":
      //    console.log('vistingdoctordata', action);
      return { ...state, vistingdoctordata: action.value };

    case "visitingdoctorprocedure":
      // console.log('visitingdoctorprocedure', action);
      return { ...state, visitingdoctorprocedure: action.value };

    case "TestData":
      // console.log(action);
      return { ...state, TestData: action.value };
    case "Agesetup":
      // console.log(action);
      return { ...state, Agesetup: action.value };
    case "TestMaster":
      // console.log(action)
      return { ...state, TestMaster: action.value };
    case "Capturesample":
      // console.log(action)
      return { ...state, Samplecapture: action.value };
    case "GroupMaster":
      // console.log(action)
      return { ...state, GroupMasterData: action.value };

    case "Barcodedata":
      // console.log(action)
      return { ...state, barcodedata: action.value };
    case "Navigationlab":
      // console.log(action)
      return { ...state, navigationlab: action.value };
      

    case "forpatientdatalab":
      // console.log('forpatientdatalab', action)
      return { ...state, forpatientdatalab: action.value };
    case "foreditcontent":
      // console.log('foreditcontent', action)
      return { ...state, foreditcontent: action.value };
    case "senioreditdoc":
      // console.log('senioreditdoc', action)
      return { ...state, senioreditdoc: action.value };
    case "iseditedsenior":
      // console.log('iseditedsenior', action)
      return { ...state, iseditedsenior: action.value };

    case "pathalogydoc":
      // console.log('pathalogydoc', action)
      return { ...state, pathalogydoc: action.value };
    case "PrintBarcode":
      // console.log('PrintBarcode', action)
      return { ...state, PrintBarcode: action.value };

    case "selecteddepartment":
      // console.log('PrintBarcode', action)
      return { ...state, selecteddepartment: action.value };
    case "OfferPackageData":
      console.log(action);
      return { ...state, OfferPackageData: action.value };
    case "SelectedPateintDeuBilling":
      return { ...state, SelectedPateintDeuBilling: action.value };
    case "ExternalLabdata":
      return { ...state, ExternalLabdata: action.value };
    case "RequestDataforBilling":
      return { ...state, RequestDataforBilling: action.value };

    default:
      return state;
  }
};

export default userRecorddata;

//      const  isSidebarOpen = useSelector(state=>state.userRecord?.isSidebarOpen )

//      const userRecord=useSelector(state=>state.userRecord?.UserData)

//        var(--ProjectColor)
